#app {
  margin: 0 auto;
  background: #fff;
  max-width: 1440px;


}

* {
  font-family: Source Sans Pro;
}
.wrapper {
  margin: 0 auto;
  //overflow: hidden;
  padding: 20px 16px 20px;
}
.header {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .course-item__progress {
    width: 470px;
  }
}
section h1 {
  font-size: 46px;
  line-height: 58px;
  margin-bottom: 50px;
}
img {
  max-width: 100%;
}
.logo {
  background: url("/images/logo.png");
  width: 245px;
  height: 80px;
  display: block;
  background-size: cover;
}
.top-menu {
  color: rgba(0, 140, 140, 1);
  letter-spacing: 0;
}

.top-menu a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  margin: 0 25px;
  font-size: 18px;

  &:last-child {
    margin-right: 0;
  }
}
.top-menu a:hover {
  text-decoration: underline;
  color: inherit;
}
.main-block {
  height: 700px;
}
.main-block .main-text {
  margin: 190px 0 0 130px;
}
.main-block p {
  font-size: 26px;
  line-height: 35px;
}
.main-block h1 {
  font-size: 50px;
  line-height: 63px;
  font-weight: 700;
}
.main-block img {
  max-width: 100%;
  display: block;
  margin: 105px 55px 105px 0;
}
.users-count {
  background: #F4F5FA;
  padding: 100px 130px;
}
.users-count {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}
.count-block {
  width: 341px;
  height: 208px;
  background: #fff;
  float: right;
  padding: 24px;
  text-align: center;
}
.count-block h3 {
  color: #008C8C;
  font-weight: bold;
  font-style: normal;
  font-size: 50px;
  line-height: 63px;
}
.count-block {
  font-size: 18px!important;
  line-height: 21px;
}
.for-whom-block {
  padding: 100px 130px 0;
}
.for-whom-block .whom-block {
  box-shadow: 0 4px 27px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 50px;
}
.whom-block h2 {
  font-weight: bold;
  font-size: 23px;
  line-height: 27px;
  letter-spacing: -0.02em;
}
.whom-block svg {
  margin: 24px 0;
}
.whom-block h3 {
  font-size: 20px;
  font-weight: bold;
}
.courses {
  padding: 100px 0;
}
.courses h1 {
  padding: 0 130px;
}
.courses .lines {
  padding: 56px 130px;
  background: #F4F5FA;
}
.lines h4 {
  font-size: 36px;
  line-height: 42px;
}
.lines img {
  width: 100%;
}
.lines .btn {
  margin-top: 157px;
}
.education-block {
  padding-left: 52px;
}
.education-block .icon-block {
  display: inline-block;
  margin-right: 60px;
  padding-top: 24px;
}
.education-block p {
  margin: 20px 0 0;
}
.btn-success {
  background: #009999!important;
}
.lines:nth-child(odd) {
  background: #fff;
}
.education, footer {
  background: #F4F5FA;
  padding: 100px 130px;
}
.education h2 {
  margin-bottom: 32px;
}
.education .row {
  margin-bottom: 50px;
}
.start {
  padding: 100px 130px;
  text-align: center;
  font-size: 46px;
  line-height: 58px;
}

.dropdown {
  & &-toggle {
    padding: 5px 24px 5px 8px;
    color: $primary;
    text-decoration: none;
    border-radius: 4px;
    background: transparent;
    border: 0;

    &:after {
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 0;
      vertical-align: 0;
      content: "";
      border: 0;
      width: 10px;
      height: 6px;
      background-size: contain;
      background: no-repeat 0 0 url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.49722 5.21396C5.22235 5.48883 4.78468 5.49681 4.50182 5.21396L0.497217 1.20935C0.249833 0.961969 0.22437 0.576975 0.424462 0.299739L0.499519 0.211654C0.775661 -0.0644883 1.21458 -0.0732885 1.49 0.202133L4.99952 3.71165L8.50904 0.202133C8.75284 -0.0416628 9.13485 -0.0641488 9.41153 0.136503L9.49952 0.211654C9.77566 0.487797 9.78467 0.926498 9.50182 1.20935L5.49722 5.21396Z' fill='%23008080'/%3E%3C/svg%3E ");
    }

    &:focus,
    &:active,
    &:hover {
      background-color: $primary-light;
      color: $primary;
    }
  }
  & &-menu {
    position: absolute;
    top: 100% !important;
    margin-top: 4px;
    right: 0;
    left: auto !important;
    background: $white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    transform: none !important;
  }

  & &-item {
    color: $black;
    font-size: 16px;

    &:hover {
      background: $primary-light;
    }

    &:focus,
    &:active {
      color: $black;
    }
  }

  .logout a {
    color: $red;
  }
}

.show > .btn-secondary.dropdown-toggle {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.49722 5.21396C5.22235 5.48883 4.78468 5.49681 4.50182 5.21396L0.497217 1.20935C0.249833 0.961969 0.22437 0.576975 0.424462 0.299739L0.499519 0.211654C0.775661 -0.0644883 1.21458 -0.0732885 1.49 0.202133L4.99952 3.71165L8.50904 0.202133C8.75284 -0.0416628 9.13485 -0.0641488 9.41153 0.136503L9.49952 0.211654C9.77566 0.487797 9.78467 0.926498 9.50182 1.20935L5.49722 5.21396Z' fill='%23ffffff'/%3E%3C/svg%3E ");
    transform: rotate(180deg) translateY(3px);
  }
}
