/**
 * modal
 */
.modal {
  &-backdrop {
    opacity: 0.7 !important;
  }

  & &-content {
    border-radius: 0;
    border: 0;
  }

  & &-header {
    border-bottom: 0;
  }

  & &-title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    flex: 1;
  }

  & &-body {
    padding-left: 36px;
    padding-right: 36px;
  }

  .close:active,
  .close:focus,
  .close {
    text-indent: -10000px;
    border: 0;
    background: no-repeat 50% 50% url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683418 14.0976 1.31658 14.0976 1.70711 13.7071L7.00003 8.41418L12.293 13.7071C12.6835 14.0977 13.3167 14.0977 13.7072 13.7071C14.0977 13.3166 14.0977 12.6834 13.7072 12.2929L8.41425 6.99997L13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976312 12.6834 -0.0976308 12.2929 0.292893L7.00003 5.58575L1.7072 0.292923C1.31668 -0.0976012 0.683511 -0.0976011 0.292986 0.292923C-0.097538 0.683447 -0.097538 1.31661 0.292986 1.70714L5.58582 6.99997L0.292893 12.2929Z' fill='black' fill-opacity='0.3'/%3E%3C/svg%3E ");
  }

  &-login {
    padding-bottom: 30px;

    h2 {
      font-size: 36px;
      font-weight: 800;
      color: #4C5156;
      margin: 0 0 32px;
      font-family: 'Open Sans';
    }

    p {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  &-profile {
    .btn {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}