



































@import "assets/style/main";
.custom-control {
  label {
    margin-left: 10px;
    cursor: pointer;
  }
}
ul {
  list-style: none;
}
