/**
 * form
 */
.form {

  &-group {
    margin-bottom: 16px;
  }

  // TODO: order include bootstrap for override variables
  &-control {
    color: $black !important;
    font-size: 18px !important;

    &:focus {
      box-shadow: 0 0 0 .25em $primary-light !important;
      border-color: $primary !important;
    }
  }
}

label {
  font-size: 14px;
  margin-bottom: 2px;
  color: $color-label;
}

.custom-checkbox {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-bottom: 8px;

  label {
    color: $black;
    flex-grow: 1;
    margin-bottom: 0;
    padding-left: 22px;

    &:before {
      width: 14px;
      height: 14px;
      border-radius: 3px;
      border: 1px solid #ccc;
      background: #fff no-repeat 50% 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: '';
    }
  }

  input {
    opacity: 0;
    position: absolute;
    left: 0;

    &:checked + label:before {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.65732 0.246617C10.0734 0.609781 10.1163 1.24149 9.75315 1.65758L4.95286 7.15744C4.60001 7.56172 3.99096 7.61544 3.57279 7.27916L0.838204 5.08008C0.407819 4.73398 0.339495 4.10451 0.685598 3.67413C1.0317 3.24374 1.66117 3.17542 2.09155 3.52152L4.07765 5.11868L8.24636 0.342448C8.60952 -0.0736415 9.24123 -0.116546 9.65732 0.246617Z' fill='%23593D14'/%3E%3C/svg%3E");
      border-color: #F2C535;
    }
  }
}