/**
 * study
 */
.block-study {
  margin-top: 20px;

  h1 {
    font-size: 36px;
    font-weight: 700;
  }

  &__list {
    width: 360px;

    &-item {
      margin-bottom: 12px;
      padding: 8px 12px 10px;
      border-radius: 8px;
      border: 1px solid transparent;
      cursor: pointer;
      transition: .3s ease-in-out;

      &:hover,
      &.active {
        border: 1px solid #82D9D9;
        box-shadow: 0 0 20px rgba(14, 14, 18, 0.05);
      }

      &-title {
        margin-bottom: 9px;
        font-size: 16px;
        line-height: 1.35;
      }

      &.ended &-title {
        span {
          padding-right: 22px;
          background: no-repeat 0 50% url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM12.8839 6.88388C13.372 6.39573 13.372 5.60427 12.8839 5.11612C12.3957 4.62796 11.6043 4.62796 11.1161 5.11612L7 9.23223L4.88388 7.11612C4.39573 6.62796 3.60427 6.62796 3.11612 7.11612C2.62796 7.60427 2.62796 8.39573 3.11612 8.88388L6.11612 11.8839C6.60427 12.372 7.39573 12.372 7.88388 11.8839L12.8839 6.88388Z' fill='%2332C437'/%3E%3C/svg%3E ");
        }
      }

      &.active {
        border: 1px solid #82D9D9;
        box-shadow: 0 0 20px rgba(14, 14, 18, 0.05);
      }

      &.active &-title {
        font-weight: 700;
      }
    }
  }

  &__complete {
    h1 {
      margin-bottom: 0;
    }

    &-result {
      max-width: 900px;
      margin: 0 auto 80px;
      height: 240px;
      background: $white;
      box-shadow: 0 0 35px rgba($black, 0.1);
      border-radius: 20px;
      background: no-repeat 100% 0 url("data:image/svg+xml,%3Csvg width='474' height='145' viewBox='0 0 474 145' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='296.651' y1='62.7893' x2='450.222' y2='-121.644' stroke='%23F6D671' stroke-width='50'/%3E%3Cline x1='333.511' y1='92.5611' x2='487.082' y2='-91.8726' stroke='white' stroke-width='50'/%3E%3Cline x1='370.371' y1='122.333' x2='523.942' y2='-62.1009' stroke='%23F6D671' stroke-width='50'/%3E%3Cline y1='-25' x2='240' y2='-25' transform='matrix(-0.639881 -0.768474 -0.768474 0.639881 243.555 78.7864)' stroke='%23F6D671' stroke-width='50'/%3E%3Cline y1='-25' x2='240' y2='-25' transform='matrix(-0.639881 -0.768474 -0.768474 0.639881 206.695 108.558)' stroke='white' stroke-width='50'/%3E%3Cline y1='-25' x2='240' y2='-25' transform='matrix(-0.639881 -0.768474 -0.768474 0.639881 169.835 138.33)' stroke='%23F6D671' stroke-width='50'/%3E%3C/svg%3E ");

      .col {
        padding: 33px 15px 33px 75px;
      }
      h2 {
        font-size: 48px;
        color: #F2C535;
        font-family: Roboto;
        font-weight: 700;
        margin: 0 0 30px;
      }


      &-text {
        font-size: 28px;
      }
    }

    &-diagramm {
      width: 292px;
      height: 292px;
      margin-top: -26px;
      margin-right: 46px;
    }

    &-data {
      max-width: 356px;
      margin: 0 auto 140px;

      .btn {
        padding-left: 35px;
        padding-right: 35px;
      }
    }

    &-footer {
      border-top: 1px solid #ECEDEF;
      padding-top: 36px;
      padding-bottom: 36px;
      font-size: 20px;
      text-align: center;

      a {
        color: $primary;
      }
    }

    .text-label {
      font-family: Roboto;
      font-weight: 900;
      font-size: 26px;
    }

    &-docs {
      margin-bottom: 100px;
    }

    &-share {
      p {
        font-size: 20px;
      }
    }

    &-socials {
      padding-top: 20px;
      border-top: 1px solid #ECEDEF;
    }
  }

  &__description {
    margin: -8px 0 100px;
    font-size: 18px;
  }
}

.study-item {
  &__title {
    font-weight: 700;
    font-size: 20px;
  }

  &__text {
    font-size: 16px;
  }

  &__video {
    position: relative;
    border: 2px solid $white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(14, 14, 18, 0.2);

    img {
      display: block;
      width: 100%;
    }

    .video-play {
      width: 117px;
      height: 74px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      background: no-repeat 0 0 url("data:image/svg+xml,%3Csvg width='117' height='75' viewBox='0 0 117 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='117' height='74.2373' rx='8' fill='black' fill-opacity='0.8'/%3E%3Cpath d='M78 37.1186L48.75 53.4116L48.75 20.8256L78 37.1186Z' fill='%23FAFAFA'/%3E%3C/svg%3E ");
    }
  }

  &__task {
    max-width: 615px;
    margin: 0 auto;
  }

  .btn {
    padding-left: 50px;
    padding-right: 50px;
    border-color: rgba($black, 0.1);
  }
}



