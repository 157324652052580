/**
 * courses
 */
.block-courses {
  margin-top: 90px;
}
.course-item {
  margin-bottom: 45px;

  &__image {
    width: 230px;
  }

  &__videos {
    padding-left: 32px;
    font-size: 17px;
    line-height: 1.35;
    background: no-repeat 0 50% url("data:image/svg+xml,%3Csvg width='25' height='19' viewBox='0 0 25 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.8166 18.0501H2.2452C1.79054 18.0501 1.35451 17.8695 1.03302 17.548C0.711526 17.2265 0.530914 16.7905 0.530914 16.3358V2.62151C0.530914 2.16686 0.711526 1.73082 1.03302 1.40933C1.35451 1.08784 1.79054 0.907227 2.2452 0.907227H16.8166C17.2713 0.907227 17.7073 1.08784 18.0288 1.40933C18.3503 1.73082 18.5309 2.16686 18.5309 2.62151V6.10151L23.1766 2.78437C23.3046 2.69328 23.4551 2.63909 23.6117 2.62771C23.7683 2.61634 23.9251 2.64821 24.0648 2.71985C24.2046 2.7915 24.3219 2.90016 24.4041 3.03397C24.4863 3.16779 24.5302 3.32161 24.5309 3.47865V15.4787C24.5302 15.6357 24.4863 15.7895 24.4041 15.9233C24.3219 16.0572 24.2046 16.1658 24.0648 16.2375C23.9251 16.3091 23.7683 16.341 23.6117 16.3296C23.4551 16.3182 23.3046 16.264 23.1766 16.1729L18.5309 12.8558V16.3358C18.5309 16.7905 18.3503 17.2265 18.0288 17.548C17.7073 17.8695 17.2713 18.0501 16.8166 18.0501ZM2.2452 2.62151V16.3358H16.8166V11.1929C16.8174 11.0359 16.8612 10.8821 16.9434 10.7483C17.0256 10.6144 17.143 10.5058 17.2827 10.4341C17.4225 10.3625 17.5792 10.3306 17.7359 10.342C17.8925 10.3534 18.043 10.4076 18.1709 10.4987L22.8166 13.8158V5.14151L18.1709 8.45865C18.043 8.54974 17.8925 8.60393 17.7359 8.61531C17.5792 8.62669 17.4225 8.59481 17.2827 8.52317C17.143 8.45153 17.0256 8.34286 16.9434 8.20905C16.8612 8.07524 16.8174 7.92141 16.8166 7.76437V2.62151H2.2452Z' fill='%23008C8C'/%3E%3C/svg%3E");
  }

  &__tasks {
    margin-left: 20px;
    padding-left: 32px;
    font-size: 17px;
    line-height: 1.35;
    background: no-repeat 0 50% url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.0309 20.4072H2.03091V21.9072H23.0309V20.4072Z' fill='%23008C8C'/%3E%3Cpath d='M19.5809 7.65723C20.1809 7.05723 20.1809 6.15723 19.5809 5.55723L16.8809 2.85723C16.2809 2.25723 15.3809 2.25723 14.7809 2.85723L3.53091 14.1072V18.9072H8.33091L19.5809 7.65723ZM15.8309 3.90723L18.5309 6.60723L16.2809 8.85723L13.5809 6.15723L15.8309 3.90723ZM5.03091 17.4072V14.7072L12.5309 7.20723L15.2309 9.90723L7.73091 17.4072H5.03091Z' fill='%23008C8C'/%3E%3C/svg%3E");
  }

  &__text {
    font-size: 21px;
    line-height: 1.2;
  }

  &__progress {

    &-bg {
      border-radius: 10px;
      background: $bluegray;
    }

    &-percent {
      height: 10px;
      background: $primary;
      border-radius: 10px;
      transition: .3s ease-in-out;
    }
  }

  &__percent {
    font-size: 20px;
    line-height: 1.15;
    font-weight: bold;
    margin-left: 20px;
  }

  &__actions {
    width: 210px;

    .btn {
      display: block;
      width: 100%;
    }
  }

  h2 {
    font-size: 36px;
    line-height: 1.2;
    font-weight: bold;
    margin: 0 0 15px;

    a {
      color: $black;
      text-decoration: none;

      &:hover {
        color: $primary;
      }
    }
  }

}